import {
  ActionType,
  commissionPaymentActionType,
} from "./commissionPaymentActions";

export interface IcommissionPaymentState {
  commissionPayments: any[];
  commissionPaymentInfo: any;
  loginUserInfo: any;
  filters: any[];
  dataCount: number;
  isLoading: boolean;
}

const initialState: IcommissionPaymentState = {
  commissionPayments: [],
  commissionPaymentInfo: {},
  loginUserInfo: {},
  filters: [],
  dataCount: 0,
  isLoading: false,
};

export const commissionPaymentReducer = (
  state: IcommissionPaymentState = initialState,
  action: ActionType
) => {
  switch (action.type) {
    case commissionPaymentActionType.GET_COMMISSION_PAYMENTLIST_SUCCESS:
      state = {
        ...state,
        commissionPayments: action.payload?.data,
        dataCount: action.payload?.dataCount,
      };
      return state;
    case commissionPaymentActionType.GET_COMMISSION_PAYMENT_INFO:
      state = {
        ...state,
        commissionPaymentInfo: action.payload,
      };
      return state;
    case commissionPaymentActionType.LOAD_COMMISSION_PAYMENT:
      state = {
        ...state,
        isLoading: action.payload,
      };
      return state;
    case commissionPaymentActionType.RESET_COMMISSION_PAYMENT_INFO:
      state = {
        ...state,
        commissionPaymentInfo: action.payload,
      };
      return state;
    case commissionPaymentActionType.GET_USERTYPE_AND_COMMISSION_RATE:
      state = {
        ...state,
        loginUserInfo: action.payload,
      };
      return state;
    default:
      return state;
  }
};
