import { Action } from "redux";
import { Dispatch } from "@reduxjs/toolkit";
import productDataaccess from "src/dataAccess/products/product.dataaccess";

import configurableTypeDataaccess from "src/dataAccess/configurableTypes/configurableType.dataaccess";

export enum productActionType {
  LOAD_PRODUCT = "[Product] Loading product",
  GET_PRODUCT_LIST = "[Product] Get Product list",
  GET_PRODUCT_LIST_SUCCESS = "[Product] Get Product list success",
  GET_PRODUCT_BASIC_INFO = "[Product] Get Product Basic Info",
  GET_PRODUCT_OTHER_INFO = "[Product] Get product other info",
  SAVE_PRODUCT_OTHER_INFO = "[Product] Save product other info",
  GET_PRODUCT_ALLERGENS = "[Product] Get product allergens",
  GET_COUNTRIES = "Get All Countries",
  GET_SOURCE_PRODUCTS = "Get All Source Products",
  GET_CATEGORIES = "Get All Categories",
  GET_PRODUCT_COMMENTS = "[Product] Get Product comments",
  SAVE_PRODUCT_COMMENTS = "[Product] Save Product comments",
  GET_PRODUCT_MEASURMENTS = "Get Product Measurments",
  GET_PACKSIZES = "Get Pack Sizes",
  LOAD_FINISHED_PRODUCTS = "Load Finished Products By serach term",
  GET_AREAS = "Get All Areas",
  GET_UOMLIST = "Get All UOM",
  GET_UnitConversionLIST = "GET Unit Conversion list",
  GET_RACK_Products = "GET_RACK_Products",
  GET_PRODUCT_Name = "GET_PRODUCT_Name",
  GET_PRODUCT_BYBEDTBEFORE_LIST_SUCCESS = "GET_PRODUCT_BYBEDTBEFORE_LIST_SUCCESS",
  UPDATE_TAB_NAME = "Update Tab Name",
  UPDATE_Qty_In_Stock = "Update Qty In Stock",
  GET_Batch_Delete_Reasons = "Get Batch Delete Reasons",
}

interface ProductAction extends Action {
  payload: any;
}

export const actions = {
  getProductList:
    (pageNo = 1, pageSize = 25, sortData = "", filters = []) =>
    (dispatch: Dispatch, getState) => {
      dispatch({ type: productActionType.LOAD_PRODUCT, payload: true });
      productDataaccess
        .productList({ pageNo, pageSize, sortData, filters })
        .then((res) => {
          dispatch({
            type: productActionType.GET_PRODUCT_LIST_SUCCESS,
            payload: res,
          });
          dispatch({ type: productActionType.LOAD_PRODUCT, payload: false });
        });
    },
  getProductByBestbeforeUseByList:
    (pageNo = 1, pageSize = 25, sortData = "", filters = []) =>
    (dispatch: Dispatch, getState) => {
      dispatch({ type: productActionType.LOAD_PRODUCT, payload: true });
      productDataaccess
        .productBestBeforeList({ pageNo, pageSize, sortData, filters })
        .then((res) => {
          dispatch({
            type: productActionType.GET_PRODUCT_BYBEDTBEFORE_LIST_SUCCESS,
            payload: res,
          });
          dispatch({ type: productActionType.LOAD_PRODUCT, payload: false });
        });
    },
  getProductBasicInfo: (productId) => (dispatch: Dispatch, getState) => {
    dispatch({ type: productActionType.LOAD_PRODUCT, payload: true });
    productDataaccess.getProductBasicInfo(productId).then((res) => {
      dispatch({
        type: productActionType.GET_PRODUCT_BASIC_INFO,
        payload: res,
      });
      dispatch({ type: productActionType.LOAD_PRODUCT, payload: false });
    });
  },
  getMeasurments: (productId) => (dispatch: Dispatch, getState) => {
    dispatch({ type: productActionType.LOAD_PRODUCT, payload: true });
    productDataaccess.getMeasurments(productId).then((res) => {
      dispatch({
        type: productActionType.GET_PRODUCT_MEASURMENTS,
        payload: res,
      });
      dispatch({ type: productActionType.LOAD_PRODUCT, payload: false });
    });
  },
  getProductOtherInfo: (productId) => (dispatch: Dispatch, getState) => {
    dispatch({ type: productActionType.LOAD_PRODUCT, payload: true });
    productDataaccess.getProductOtherInfo(productId).then((res) => {
      dispatch({
        type: productActionType.GET_PRODUCT_OTHER_INFO,
        payload: res,
      });
      dispatch({ type: productActionType.LOAD_PRODUCT, payload: false });
    });
  },
  SaveProductOtherInfo: (payload) => (dispatch: Dispatch, getState) => {
    productDataaccess.saveProductOtherInfo(payload).then((res) => {
      dispatch({
        type: productActionType.SAVE_PRODUCT_OTHER_INFO,
        payload: res,
      });
    });
  },
  getProductAllergens: (productId) => (dispatch: Dispatch, getState) => {
    dispatch({ type: productActionType.LOAD_PRODUCT, payload: true });
    productDataaccess.getProductAllergens(productId).then((res) => {
      dispatch({
        type: productActionType.GET_PRODUCT_ALLERGENS,
        payload: res,
      });
      dispatch({ type: productActionType.LOAD_PRODUCT, payload: false });
    });
  },
  getAllConfigurableTypeDropdown:
    (type: "COUTY" | "MUNIT") => (dispatch: Dispatch, getState) => {
      productDataaccess.getAllConfigurableTypeDropdown(type).then((res) => {
        switch (type) {
          case "COUTY":
            dispatch({ type: productActionType.GET_COUNTRIES, payload: res });
            break;
          case "MUNIT":
            dispatch({ type: productActionType.GET_UOMLIST, payload: res });
            break;
          default:
            break;
        }
      });
    },
  getUnitConversionDropdown: (uomId) => (dispatch: Dispatch, getState) => {
    configurableTypeDataaccess.getUnitConversionDropdown(uomId).then((res) => {
      dispatch({
        type: productActionType.GET_UnitConversionLIST,
        payload: res,
      });
    });
  },
  getAllPackSiesDropdown: () => (dispatch: Dispatch, getState) => {
    // packsizeDataaccess.getAllPackSizesDropdown().then((res) => {
    //   dispatch({ type: productActionType.GET_PACKSIZES, payload: res });
    // });
  },
  getAllSourceProductForDropdown:
    (productId) => (dispatch: Dispatch, getState) => {
      productDataaccess
        .getAllSourceProductForDropdown(productId)
        .then((res) => {
          dispatch({
            type: productActionType.GET_SOURCE_PRODUCTS,
            payload: res,
          });
        });
    },
  getAllCategoryForDropdown: () => (dispatch: Dispatch, getState) => {
    productDataaccess.getAllCategoryForDropdown().then((res) => {
      dispatch({
        type: productActionType.GET_CATEGORIES,
        payload: res,
      });
    });
  },
  getProductComments: (productId) => (dispatch: Dispatch, getState) => {
    dispatch({ type: productActionType.LOAD_PRODUCT, payload: true });
    productDataaccess.getProductComments(productId).then((res) => {
      dispatch({
        type: productActionType.GET_PRODUCT_COMMENTS,
        payload: res,
      });
      dispatch({ type: productActionType.LOAD_PRODUCT, payload: false });
    });
  },
  saveProductComments: (payload) => (dispatch: Dispatch, getState) => {
    productDataaccess.saveProductComments(payload).then((res) => {
      dispatch({
        type: productActionType.SAVE_PRODUCT_COMMENTS,
        payload: res,
      });
    });
  },
  getFinishedProductDropdownDataBySearchTerm:
    (inputValue) => (dispatch: Dispatch, getState) => {
      productDataaccess
        .getFinishedProductDropdownDataBySearchTerm(inputValue)
        .then((res) => {
          dispatch({
            type: productActionType.LOAD_FINISHED_PRODUCTS,
            payload: res,
          });
        });
    },
  getAllAreaForDropdown: () => (dispatch: Dispatch, getState) => {
    productDataaccess.getAllAreaForDropdown().then((res) => {
      dispatch({
        type: productActionType.GET_AREAS,
        payload: res,
      });
    });
  },
  getRackProducts: (productId) => (dispatch: Dispatch, getState) => {
    productDataaccess.getRackProducts(productId).then((res) => {
      dispatch({
        type: productActionType.GET_RACK_Products,
        payload: res,
      });
    });
  },
  updateProductNameState: (res) => (dispatch: Dispatch, getState) => {
    dispatch({
      type: productActionType.GET_PRODUCT_Name,
      payload: res,
    });
  },
  updateTabNameState: (res) => (dispatch: Dispatch, getState) => {
    dispatch({
      type: productActionType.UPDATE_TAB_NAME,
      payload: res,
    });
  },
  updateQtyState: (res) => (dispatch: Dispatch, getState) => {
    dispatch({
      type: productActionType.UPDATE_Qty_In_Stock,
      payload: res,
    });
  },
  getBatchDeleteReasons: (res) => (dispatch: Dispatch, getState) => {
    dispatch({
      type: productActionType.GET_Batch_Delete_Reasons,
      payload: res,
    });
  },
};

export type ActionType = ProductAction;
