import moment from "moment";
import { IFilter } from "src/@redux/listRedux";
import jwt from "jwt-decode";
import { localStorageKeys } from "./constants";
import { ValueLabel } from "src/models/common.models";
import { AccessLevelEnum } from "./enums";
import clientProductDataAccess from "src/dataAccess/clientProducts/clientProductDataAccess";
import { notification } from "antd";

interface ValueLabelGeneratorProps {
  data: any[];
  valueProp: string;
  labelProp: string;
}

const signatures = {
  iVBORw0KGgo: "image/png",
  "/9j/": "image/jpg",
};

export const getMimeType = (base64) => {
  for (const sign in signatures)
    if (base64.startsWith(sign)) return signatures[sign];
};

export const GenerateFields = (object: any) => {
  let fields: any[] = [];
  Object.keys(object).forEach((item) => {
    fields.push({
      name: [item],
      value: object[item],
    });
  });
  return fields;
};

export const dropDownValueLabelFromData = (
  prop: ValueLabelGeneratorProps
): ValueLabel[] => {
  let data = prop?.data?.map((item) => {
    let valueLabel: ValueLabel = {
      value: item[prop.valueProp],
      label: item[prop.labelProp],
    };
    return valueLabel;
  });
  return data;
};

export const getFileValueFromEvent = (props) => {
  if (Array.isArray(props)) {
    return props;
  }
  return props && props.file;
};

export const isValidDate = (item: any) => {
  let dt = new Date(item);
  if (Object.prototype.toString.call(dt) === "[object Date]") {
    if (isNaN(dt.getTime())) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
};

export const ConvertToFormData = (object: any) => {
  let formData = new FormData();
  Object.keys(object).forEach((item) => {
    if (object[item] !== undefined) {
      let value = null;
      let appendArray = false;
      if (typeof object[item] == typeof object) {
        if (isValidDate(object[item])) {
          value = moment(object[item]).utc().format("yyyy-MM-DDTHH:mm:ss");
        } else if (
          Object.prototype.toString.call(object[item]) === "[object Array]"
        ) {
          appendArray = true;
        } else {
          value = object[item];
        }
      } else {
        value = object[item];
      }
      if (appendArray) {
        object[item].forEach((itm) => {
          formData.append(`${item}[]`, itm);
        });
      } else {
        formData.append(item, value);
      }
    }
  });
  return formData;
};
const manipulateObject = (data) => {
  return new Promise(async (resolve, reject) => {
    let buildObj = {};
    await Object.keys(data).forEach(async (key) => {
      let id, name;
      [name, id] = key?.split("-");
      if (!buildObj[id]) {
        buildObj[id] = { id: id };
      }
      buildObj[id][name] = data[key];
    });

    resolve(buildObj);
  });
};

export const formTableListExtension = {
  toArrayObjectPayload: function () {
    let buildObj = {};
    Object.keys(this).forEach((key) => {
      let id, name;
      [name, id] = key?.split("-");
      if (!buildObj[id]) {
        buildObj[id] = { id: id ?? 0 };
      }
      buildObj[id][name] = this[key];
    });
    let payloadList = Object.keys(buildObj).map((key) => buildObj[key]);
    return payloadList;
  },
};

export const listFormToListPayload = async (data: Object): Promise<any[]> => {
  return new Promise(async (resolve) => {
    await manipulateObject(data).then((res) => {
      let payload = Object.keys(res).map((item) => {
        return res[item];
      });
      resolve(payload);
    });
  });
};

export const toBase64 = async (file: File) => {
  if (!(file instanceof File)) {
    return null;
  }
  return new Promise<any>(async (resolve, reject) => {
    let fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () =>
      resolve(fileReader.result.toString().split(",")[1]);
    fileReader.onerror = () => reject("");
  });
};

export const formatFilterForQueryParam = (filters: Array<IFilter>) => {
  let _filters = {};
  filters.forEach((item, index) => {
    if (
      item?.value instanceof Array &&
      item?.type instanceof Array &&
      item?.value?.length > 0 &&
      item?.type?.length > 0
    ) {
      let [value1, value2] = item.value;
      let [type1, type2] = item.type;
      _filters["filter." + index + "~" + item.propertyName] =
        value1 + "-" + type1;
      _filters["filter." + index + 1 + "~" + item.propertyName] =
        value2 + "-" + type2;
    } else if (item.value && item.value.length > 0) {
      _filters["filter." + index + "~" + item.propertyName] =
        item.value + "-" + item.type;
    }
  });

  return _filters;
};

export const IgnoreEmptyProperty = (data: any) => {
  let dataMinify = {};
  Object.keys(data).forEach((key) => {
    if (data[key] !== "") {
      dataMinify[key] = data[key];
    }
  });
  return dataMinify;
};

export const sortTableData = (
  sortValue: string,
  data: any[],
  setData: (data: any) => void
) => {
  let [propertyName, direction] = sortValue.split("-");
  let cpy = [...data];
  let sorted = cpy.sort((a, b) => {
    if (direction === "asc") {
      return a[propertyName] < b[propertyName] ? -1 : 1;
    } else {
      return b[propertyName] < a[propertyName] ? -1 : 1;
    }
  });
  setData(sorted);
};

export interface TokenModel {
  unique_name: string;
  Id: number;
  //OrganizationId: number;
  DepartmentId: number;
  IsSuperUser: boolean | string;
  UserType: string;
  //IsTrainingManager: boolean | string;
  //IsTrainer: boolean | string;
  SettingId: number;
}

export interface UserModel {
  firstName: string;
  lastName: string;
}

export const getTokenInfo = (): TokenModel => {
  let tokenDecode = jwt<TokenModel>(
    localStorage.getItem(localStorageKeys.authorization)
  );
  let detail: TokenModel = {
    unique_name: tokenDecode.unique_name,
    Id: Number(tokenDecode.Id),
    //OrganizationId: Number(tokenDecode.OrganizationId),
    DepartmentId: Number(tokenDecode.DepartmentId) ?? 0,
    IsSuperUser: tokenDecode.IsSuperUser === "True",
    UserType: tokenDecode.UserType,
    //IsTrainingManager: tokenDecode.IsTrainingManager === "True",
    //IsTrainer: tokenDecode.IsTrainer === "True",
    SettingId: Number(tokenDecode.SettingId) ?? 0,
  };

  return detail;
};

export const setToken = (token: string) => {
  localStorage.setItem(localStorageKeys.authorization, token);
};

export const getUserInfo = () => {
  let stringData = localStorage.getItem(localStorageKeys.user);

  let userInfo: UserModel = JSON.parse(stringData);
  return userInfo;
};

export const ObjectExtensions = {
  isNullOrEmpty: function () {
    return Object.keys(this).length === 0 || (this ?? "") === "";
  },
};

export const compareDateTime = (
  date1: Date,
  date2: Date
): "less" | "greater" => {
  let firstDate = new Date(date1);
  let secondDate = new Date(date2);

  return firstDate < secondDate ? "less" : "greater";
};

interface ExceptionCountAction {
  increment: (property: string, incrementBy: number) => void;
  decrement: (property: string, decrementBy: number) => void;
}

export const exceptionCountAction: ExceptionCountAction = {
  increment: function (property: string, incrementBy: number) {
    this[property] += incrementBy;
  },
  decrement: function (property: string, decrementBy: number) {
    this[property] = this[property] === 0 ? 0 : this[property] - decrementBy;
  },
};

export const downloadStaticFile = (file: any, fileName: string) => {
  let element = document.createElement("a");
  element.href = file;
  element.setAttribute("download", fileName);
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};
export const CheckAccess = (module) => {
  let user =
    localStorage.getItem(localStorageKeys.user) !== "undefined" &&
    localStorage.getItem(localStorageKeys.user)
      ? JSON.parse(localStorage.getItem(localStorageKeys.user))
      : {};
  //console.log(user?.permissions);
  // let accessLevel = user?.permissions?.map((item) => {
  //   if (item.module === module) return item.accessLevel;
  // });
  if (user.isSuperUser)
    return AccessLevelEnum.CreateReadModifyDeleteCompletePicking;

  let permission = user?.permissions?.find((item) => {
    return item.module === module;
  })?.accessLevel;
  //console.log(accessLevel);
  if (permission) {
    let typedEnumString = permission as keyof typeof AccessLevelEnum;
    return AccessLevelEnum[typedEnumString];
  }
  //return AccessLevelEnum[accessLevel.toString()];
  return AccessLevelEnum.NoAccess;
};
export const hasReadPermission = (module: any) => {
  let accessLevel = AccessLevelEnum.NoAccess;
  accessLevel = CheckAccess(module);

  if (accessLevel >= AccessLevelEnum.ReadOnly) return true;
  return false;
};
export const hasCreatePermission = (module: any) => {
  let accessLevel = AccessLevelEnum.NoAccess;
  accessLevel = CheckAccess(module);

  if (accessLevel >= AccessLevelEnum.CreateReadModify) return true;
  return false;
};
export const hasEditPermission = (module: any) => {
  let accessLevel = AccessLevelEnum.NoAccess;
  accessLevel = CheckAccess(module);

  if (accessLevel >= AccessLevelEnum.ReadModify) return true;
  return false;
};
export const hasDeletePermission = (module: any) => {
  let accessLevel = AccessLevelEnum.NoAccess;
  accessLevel = CheckAccess(module);

  if (accessLevel >= AccessLevelEnum.CreateReadModifyDelete) return true;
  return false;
};
export const hasEditStockPermission = (module: any) => {
  let accessLevel = AccessLevelEnum.NoAccess;
  accessLevel = CheckAccess(module);

  if (accessLevel >= AccessLevelEnum.CreateReadModifyDeleteEditStock)
    return true;
  return false;
};
export const hasVerifyPermission = (module: any) => {
  let accessLevel = AccessLevelEnum.NoAccess;
  accessLevel = CheckAccess(module);

  if (accessLevel >= AccessLevelEnum.CreateReadModifyDeleteVerify) return true;
  return false;
};

export const isDateExpired = (date: any) => {
  console.log(date, "date");
  const currentDate = new Date(); // Get the current date

  if (date) {
    const inputDate = new Date(Date.parse(date));
    if (inputDate < currentDate) {
      return true;
    }
  }
  return false;
};

export const stringToBoolean = (str: string) => {
  if (str == undefined) return false;
  return str.toString().toLowerCase() === "true";
};

export const getFiscalYear = (date: any) => {
  const year = date.getFullYear();
  const month = date.getMonth();

  let startYear;
  let endYear;

  if (month >= 6) {
    startYear = year;
    endYear = year + 1;
  } else {
    startYear = year - 1;
    endYear = year;
  }

  return `${startYear}-${endYear}`;
};
