import { createReducer } from "@reduxjs/toolkit";
import { ValueLabel } from "src/models/common.models";
import {
  GetActiveUserDropdownSuccess,
  GetPeopleDropdownSuccess,
  GetPeopleEmailListSuccess,
  GettAllPeopleListSuccess,
  GetUserListDropdownByDepartmentId,
  GetUserShiftSuccess,
  //GetUserTrainingCourseListSuccess,
  //GetUserTrainingListSuccess,
  LoadingData,
  GetTrainerUserDropdownSuccess,
  //GetUserTrainingPackListSuccess,
} from "./peoplesAction";

export interface IPeopleState {
  peoples?: any[];
  peoplesDropdown?: any[];
  peopleInfo?: any;
  userShifts?: any[];
  mailList?: any[];
  activeUsers?: any[];
  trainerUsers?: any[];
  dataCount: number;
  isLoading?: boolean;
  trainingList?: any[];
  trainingCourseList?: any[];
  trainingPackList?: any[];
  trainingPackListByUser?: any[];
  lineManagers?: ValueLabel[];
}
const initialState: IPeopleState = {
  peoples: [],
  dataCount: 0,
};

export const peopleReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(GettAllPeopleListSuccess, (state, action) => {
      state = {
        ...state,
        peoples: action.payload?.data,
        dataCount: action.payload?.dataCount,
      };
      return state;
    })
    .addCase(GetUserShiftSuccess, (state, action) => {
      state = {
        ...state,
        userShifts: action.payload,
      };
      return state;
    })
    .addCase(GetPeopleEmailListSuccess, (state, action) => {
      state = {
        ...state,
        mailList: action.payload,
      };
      return state;
    })
    .addCase(GetPeopleDropdownSuccess, (state, action) => {
      state = {
        ...state,
        peoplesDropdown: action.payload,
      };
      return state;
    })
    .addCase(GetTrainerUserDropdownSuccess, (state, action) => {
      state = {
        ...state,
        trainerUsers: action.payload,
      };
      return state;
    })
    .addCase(GetActiveUserDropdownSuccess, (state, action) => {
      state = {
        ...state,
        activeUsers: action.payload,
      };
      return state;
    })
    .addCase(LoadingData, (state, action) => {
      state = {
        ...state,
        isLoading: action.payload,
      };
      return state;
    })
    .addCase(GetUserListDropdownByDepartmentId.fulfilled, (state, action) => {
      state = {
        ...state,
        lineManagers: action.payload,
      };
      return state;
    });
});
