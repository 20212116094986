import { ActionType, clientProductActionType } from "./clientProductActions";

export interface IClientProductState {
    clientProducts: any[];
    clientProductBasicInfo: any;
    // clientProducts: any[];
    filters: any[];
    dataCount: number;
    isLoading: boolean;
}

const initialState: IClientProductState = {
    // clients: [],
    clientProductBasicInfo: {},
    clientProducts: [],
    filters: [],
    dataCount: 0,
    isLoading: false
};

export const clientProductReducer = (
    state: IClientProductState = initialState,
    action: ActionType
) => {
    switch (action.type) {
        case clientProductActionType.GET_CLIENT_PRODUCT_LIST_SUCCESS:
            state = {
                ...state,
                clientProducts: action.payload?.data,
                dataCount: action.payload?.dataCount,
            };
            return state;
        case clientProductActionType.GET_CLIENT_PRODUCT_BASICINFO:
            state = {
                ...state,
                clientProductBasicInfo: action.payload,
            };
            return state;
        case clientProductActionType.RESET_CLIENT_PRODUCT_BASICINFO:
            state = {
                ...state,
                clientProductBasicInfo: action.payload,
            };
            return state;
        case clientProductActionType.LOAD_PRODUCT_CLIENT:
            state = {
                ...state,
                isLoading: action.payload,
            };
            return state;
        // case clientActionType.RESET_CLIENT_BASICINFO:
        //     state = {
        //         ...state,
        //         clientBasicInfo: action.payload,
        //     };
        //     return state;
        // case clientActionType.GET_CLIENT_PRODUCTS:
        //     state = {
        //         ...state,
        //         clientProducts: action.payload,
        //     };
        //     return state;
        default:
            return state;
    }
};
