import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { filterOptions } from "src/common/constants";
import peopleDataAccess from "src/dataAccess/maintenance/peopleDataAccess";
import { IListType } from "../store";
export interface IListPeople extends IListType {
  key: string;
  userType: string;
}
export const LoadingData = createAction<boolean>("[People] Loading data");

export const GettAllPeopleListSuccess = createAction<any>(
  "[People] Get all people list success"
);

export const GetAllPeopleList = createAsyncThunk<any, IListPeople>(
  "[People] Get all people list",
  async (parameters, thunkAPI) => {
    // if (
    //   parameters.key === "activeOnly" &&
    //   !parameters.filters.find((itm) => itm.propertyName === "UserStatus")
    // ) {
    //   var filter = {
    //     propertyName: "UserStatus",
    //     value: "Active",
    //     type: filterOptions.equals.value,
    //   };
    //   parameters.filters = parameters.filters.concat(filter);
    // } else if (parameters.key === "activeOnly") {
    //   parameters.filters.forEach((itm) => {
    //     if (itm.propertyName === "UserStatus") {
    //       itm.value = "Active";
    //     }
    //   });
    // }
    console.log(parameters);
    thunkAPI.dispatch(LoadingData(true));
    const result = await peopleDataAccess.GetList(parameters).then((res) => {
      thunkAPI.dispatch(GettAllPeopleListSuccess(res));
      thunkAPI.dispatch(LoadingData(false));
      return res;
    });
    return result;
  }
);

export const GetUserShiftSuccess = createAction<any[]>(
  "[People] Get User Shift Success"
);

export const GetUserShift = createAsyncThunk<any>(
  "[People] Get User shift",
  async (parameter, thunkAPI) => {
    const result = peopleDataAccess.getUserShifts().then((res) => {
      thunkAPI.dispatch(GetUserShiftSuccess(res?.data));
      return res;
    });
    return result;
  }
);

export const GetPeopleEmailListSuccess = createAction<any[]>(
  "[People] Get people email list success"
);
export const GetPeopleEmailList = createAsyncThunk<any>(
  "[People] Get People Email List",
  async (parameter, thunkAPI) => {
    const result = peopleDataAccess.getPeopleEmailList().then((res) => {
      thunkAPI.dispatch(GetPeopleEmailListSuccess(res?.data));
      return res;
    });
    return result;
  }
);

export const GetPeopleDropdownSuccess = createAction<any[]>(
  "[People] Get people dropdown success"
);
export const GetPeopleDropdown = createAsyncThunk<any>(
  "[People] Get people dropdown",
  async (parameter, thunkAPI) => {
    const result = peopleDataAccess.getPeoplesDropdown().then((res) => {
      thunkAPI.dispatch(GetPeopleDropdownSuccess(res?.data));
      return res;
    });
    return result;
  }
);

export const GetActiveUserDropdownSuccess = createAction<any[]>(
  "[People] Get active user dropdown success"
);
export const GetTrainerUserDropdownSuccess = createAction<any[]>(
  "[People] Get trainer user dropdown success"
);
export const GetActiveUserDropdown = createAsyncThunk<any>(
  "[People] Get active user dropdown",
  async (parameter, thunkAPI) => {
    const result = peopleDataAccess.getActiveUsers().then((res) => {
      thunkAPI.dispatch(GetActiveUserDropdownSuccess(res?.data));
      return res;
    });
    return result;
  }
);

interface ILineManager {
  departmentId: any;
  lineManager?: any;
}

export const GetUserListDropdownByDepartmentId = createAsyncThunk<
  any,
  ILineManager
>("[People] get line manager dropdown", async (parameter, thunkAPI) => {
  let result = await peopleDataAccess
    .getUserListDropdownByDepartmentId(parameter?.departmentId)
    .then((res) => res?.data);
  if ((parameter?.lineManager ?? "") !== "") {
    result = result?.concat(parameter?.lineManager);
  }

  return result;
});

export const GetUserDropDownByType = createAsyncThunk<any, string>(
  "[Department] Get department dropdown",
  async (parameter, thunkAPI) => {
    const result = await peopleDataAccess
      .getUsersForDropdownByType(parameter)
      .then((res) => res?.data);
    return result;
  }
);
