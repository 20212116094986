import { ActionType, expenseActionType } from "./expenseAction";


export interface IExpenseState {
    referrerPartnerExpenses: any[];
    referrerPartnerExpensesBasicInfo: any;
    filters: any[];
    dataCount: number;
    isLoading: boolean;
}

const initialState: IExpenseState = {
    referrerPartnerExpenses: [],
    referrerPartnerExpensesBasicInfo: {},
    filters: [],
    dataCount: 0,
    isLoading: false
};

export const expenseReducer = (
    state: IExpenseState = initialState,
    action: ActionType
) => {
    switch (action.type) {
        case expenseActionType.GET_REFERRER_PARTNER_EXPENSE_LIST_SUCCESS:
            state = {
                ...state,
                referrerPartnerExpenses: action.payload?.data,
                dataCount: action.payload?.dataCount,
            };
            return state;
        case expenseActionType.GET_REFERRER_PARTNER_EXPENSE_BASICINFO:
            state = {
                ...state,
                referrerPartnerExpensesBasicInfo: action.payload,
            };
            return state;
        case expenseActionType.LOAD_REFERRER_PARTNER_EXPENSE:
            state = {
                ...state,
                isLoading: action.payload,
            };
            return state;
        default:
            return state;
    }
};
