import { ActionType, clientActionType } from "./clientActions";

export interface IClientState {
  clients: any[];
  clientBasicInfo: any;
  clientProducts: any[];
  clientsByUserTypes: any[];
  filters: any[];
  dataCount: number;
  isLoading: boolean;
}

const initialState: IClientState = {
  clients: [],
  clientBasicInfo: {},
  clientProducts: [],
  clientsByUserTypes: [],
  filters: [],
  dataCount: 0,
  isLoading: false
};

export const clientReducer = (
  state: IClientState = initialState,
  action: ActionType
) => {
  switch (action.type) {
    case clientActionType.GET_CLIENT_LIST_SUCCESS:
      state = {
        ...state,
        clients: action.payload?.data,
        dataCount: action.payload?.dataCount,
      };
      return state;
    case clientActionType.GET_CLIENT_BY_USER_TYPE_LIST_SUCCESS:
      state = {
        ...state,
        clientsByUserTypes: action.payload?.data,
        dataCount: action.payload?.dataCount,
      };
      return state;
    case clientActionType.GET_CLIENT_BASICINFO:
      state = {
        ...state,
        clientBasicInfo: action.payload,
      };
      return state;
    case clientActionType.LOAD_CLIENT:
      state = {
        ...state,
        isLoading: action.payload,
      };
      return state;
    case clientActionType.RESET_CLIENT_BASICINFO:
      state = {
        ...state,
        clientBasicInfo: action.payload,
      };
      return state;
    case clientActionType.GET_CLIENT_PRODUCTS:
      state = {
        ...state,
        clientProducts: action.payload,
      };
      return state;
    default:
      return state;
  }
};
