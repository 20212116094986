import commonDataAccess from "../commonDataAccess";
import { IFilter } from "../../@redux/listRedux";

class expenseDataAccess {
    referrerPartnerExpenseList = async (params: {
        pageNo;
        pageSize;
        sortData;
        filters: Array<IFilter>;
    }) => {
        let _filters = {};
        console.log(params.filters);
        params.filters.forEach((item) => {
            if (item.value && item.value.length > 0) {
                _filters["filter." + item.propertyName] = item.value + "-" + item.type;
            }
        });
        let parameters = {
            pageNo: params.pageNo,
            pageSize: params.pageSize,
            sortPreference: params.sortData,
            ..._filters,
        };
        return await commonDataAccess.getData("referrerPartnerExpense/getAll", parameters);
    };

    getReferrerPartnerExpenseBasicInfo = async (params: { id: number | bigint | any }) => {
        var result = await commonDataAccess.getData(
            "referrerPartnerExpense/get/ReferrerPartnerExpenseBasicInfo/" + params,
            {}
        );
        return result;
    };
    saveReferrerPartnerExpenseBasicInfo = async (model: any) => {
        var result = await commonDataAccess.postData(
            "referrerPartnerExpense/save/ReferrerPartnerExpenseBasicInfo",
            model
        );
        return result;
    };
}

export default new expenseDataAccess();
