import { Action } from "redux";
import { Dispatch } from "@reduxjs/toolkit";
import clientDataAccess from "src/dataAccess/clients/clientDataAccess";
import { setEditPageTitle } from "../common/commonAction";
import { clientProductActionType } from "../clientProducts/clientProductActions";
import expenseDataAccess from "src/dataAccess/expenses/expenseDataAccess";

export enum expenseActionType {

    LOAD_REFERRER_PARTNER_EXPENSE = "[ReferrerPartnerExpense] Loading ReferrerPartnerExpense",
    GET_REFERRER_PARTNER_EXPENSE_LIST_SUCCESS = "[ReferrerPartnerExpense] Get ReferrerPartnerExpense list success",
    GET_REFERRER_PARTNER_EXPENSE_BASICINFO = "[ReferrerPartnerExpense] Get ReferrerPartnerExpense Basic Info",
    RESET_REFERRER_PARTNER_EXPENSE_BASICINFO = "[ReferrerPartnerExpense] Reset ReferrerPartnerExpense Basic Info",
    ADD_REFERRER_PARTNER_EXPENSE_PRODUCT = "[ReferrerPartnerExpense] Add ReferrerPartnerExpense product"
}

interface expenseAction extends Action {
    payload: any;
}

export const actions = {

    getReferrerPartnerExpenseList:
        (pageNo = 1, pageSize = 25, sortData = "", filters = []) =>
            (dispatch: Dispatch, getState) => {
                dispatch({ type: expenseActionType.LOAD_REFERRER_PARTNER_EXPENSE, payload: true });
                expenseDataAccess
                    .referrerPartnerExpenseList({ pageNo, pageSize, sortData, filters })
                    .then((res) => {
                        if (res?.success) {
                            dispatch({
                                type: expenseActionType.GET_REFERRER_PARTNER_EXPENSE_LIST_SUCCESS,
                                payload: res,
                            });
                            dispatch({ type: expenseActionType.LOAD_REFERRER_PARTNER_EXPENSE, payload: false });
                        }
                    })
                    .catch((error: Error) => {
                        // throw new SubmissionError({ _error: error.message });
                    });
            },

    getReferrerPartnerExpenseBasicInfo:
        (referrerExpenseId) => (dispatch: Dispatch, getState) => {
            dispatch({ type: expenseActionType.LOAD_REFERRER_PARTNER_EXPENSE, payload: true });
            expenseDataAccess.getReferrerPartnerExpenseBasicInfo(referrerExpenseId).then((res) => {
                if (res?.success) {
                    let data = { ...res?.data };
                    dispatch({
                        type: expenseActionType.GET_REFERRER_PARTNER_EXPENSE_BASICINFO,
                        payload: data
                    });
                    dispatch(setEditPageTitle(res?.data?.name));
                    dispatch({ type: expenseActionType.LOAD_REFERRER_PARTNER_EXPENSE, payload: false });
                }
            })
                .catch((error: Error) => {
                    // throw new SubmissionError({ _error: error.message });
                });

        },

    // saveReferrerPartnerExpenseProduct:
    //     (clientId) => (dispatch: Dispatch, getState) => {
    //         dispatch({ type: expenseActionType.ADD_REFERRER_PARTNER_EXPENSE_PRODUCT, payload: false });
    //     }
};

export type ActionType = expenseAction;
