import { Action } from "redux";
import { Dispatch } from "@reduxjs/toolkit";
import commissionPaymentDataAccess from "src/dataAccess/commissionPayments/commissionPaymentDataAccess";
import { setEditPageTitle } from "../common/commonAction";
import { localStorageKeys } from "src/common/constants";
import { dispatch } from "src";

export enum commissionPaymentActionType {
  LOAD_COMMISSION_PAYMENT = "[Commission Payment] Loading commission Payment",
  GET_COMMISSION_PAYMENTLIST_SUCCESS = "[Commission Payment] Get Commission Payment list success",
  GET_COMMISSION_PAYMENT_INFO = "[Commission Payment] Get Commission Payment Info",
  RESET_COMMISSION_PAYMENT_INFO = "[Commission Payment] Reset Commission Payment Info",
  GET_USERTYPE_AND_COMMISSION_RATE = "[Commission Payment] Get user type and commission rate",
}

interface CommissionPaymentAction extends Action {
  payload: any;
}

export const actions = {
  getCommissionPaymentList:
    (pageNo = 1, pageSize = 25, sortData = "", filters = []) =>
      (dispatch: Dispatch, getState) => {
        dispatch({
          type: commissionPaymentActionType.LOAD_COMMISSION_PAYMENT,
          payload: true,
        });
        commissionPaymentDataAccess
          .commissionPaymentList({ pageNo, pageSize, sortData, filters })
          .then((res) => {
            if (res?.success) {
              dispatch({
                type: commissionPaymentActionType.GET_COMMISSION_PAYMENTLIST_SUCCESS,
                payload: res,
              });
              dispatch({
                type: commissionPaymentActionType.LOAD_COMMISSION_PAYMENT,
                payload: false,
              });
            }
          })
          .catch((error: Error) => {
            // throw new SubmissionError({ _error: error.message });
          });
      },

  getCommissionPaymentInfo: (comPaymentId) => (dispatch: Dispatch, getState) => {
    dispatch({
      type: commissionPaymentActionType.LOAD_COMMISSION_PAYMENT,
      payload: true,
    });
    commissionPaymentDataAccess
      .getCommissionPaymentInfo(comPaymentId)
      .then((res) => {
        if (res?.success) {
          dispatch({
            type: commissionPaymentActionType.GET_COMMISSION_PAYMENT_INFO,
            payload: res?.data,
          });
          dispatch(setEditPageTitle(res?.data?.name));
          dispatch({
            type: commissionPaymentActionType.LOAD_COMMISSION_PAYMENT,
            payload: false,
          });
        }
      })
      .catch((error: Error) => {
        // throw new SubmissionError({ _error: error.message });
      });
  },

  getUserTypeAndCommissionRateById: () => async (dispatch: Dispatch, getState) => {
    const userData: any = JSON.parse(localStorage.getItem(localStorageKeys.user));
    const id = userData?.id;
    if (id) {
      await commissionPaymentDataAccess.getUserTypeAndCommissionRateById(id).then((res) => {
        dispatch({
          type: commissionPaymentActionType.GET_USERTYPE_AND_COMMISSION_RATE,
          payload: res?.data,
        });
      })
    }
  }

};

export type ActionType = CommissionPaymentAction;
