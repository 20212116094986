import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { notification } from "antd";
import departmentDataAccess from "src/dataAccess/maintenance/departmentDataAccess";
import DepartmentDataAccess from "src/dataAccess/maintenance/departmentDataAccess";

import { loaderModal } from "src/microComponent/component.service";
import { DeparmentDeleteParameter } from "src/models/common.models";
import { IListType } from "../store";

export const GetDepartmentList = createAsyncThunk<any, IListType>(
  "[Department] Get Department List",
  async (parameter, thunkAPI) => {
    const result = await DepartmentDataAccess.getList(parameter).then(
      (res) => res
    );
    return result;
  }
);

export const GetDepartmentDropDown = createAsyncThunk<any[]>(
  "[Department] Get department dropdown",
  async (parameter, thunkAPI) => {
    const result =
      await DepartmentDataAccess.getAllDepartmentForDropdown().then(
        (res) => res?.data
      );
    return result;
  }
);

export const CreateDepartmentSuccess = createAction<any>(
  "[Department] Create department success"
);

export const UpdateDepartmentSuccess = createAction<any>(
  "[Department] Update department success"
);

export const DeleteDepartmentSuccess = createAction<number[]>(
  "[Department] Delete department success"
);

export const GetSubDepartmentByDepartmentId = createAsyncThunk<any, number>(
  "[Department] Get Sub Department list By Department",
  async (id, thunkAPI) => {
    const result = await departmentDataAccess
      .getSubDepartmentByDepartmentId(id)
      .then((res) => res?.data);
    return result;
  }
);
export const GetSubDepartmentByDepartmentIdForDropdown = createAsyncThunk<
  any,
  number
>(
  "[Department] Get Sub Department list By Department for Dropdown",
  async (id, thunkAPI) => {
    const result = await departmentDataAccess
      .getSubDepartmentByDepartmentId(id)
      .then((res) => {
        //res?.data
        let data: any[] = [];
        console.log(res.data);
        Object.keys(res?.data).forEach((item, index) => {
          //console.log(res.data[item].id);
          data.push({
            label: res.data[item].name,
            value: res.data[item].id.toString(),
          });
        });
        console.log(data);
        return data;
      });
    return result;
  }
);
export const SetDepartmentTrainingList = createAction<any[]>(
  "[Department] set department training list"
);

export const RemoveTrainingFromDepartmentSuccess = createAction<string>(
  "[Department] Remove training from department success"
);
